import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`LiveSession tracking script loaded on your page provides the `}<inlineCode parentName="p">{`__ls`}</inlineCode>{` object that can be used to execute a number of helpful methods.`}</p>
    <h3 {...{
      "id": "tracking-script"
    }}>{`Tracking script`}</h3>
    <p>{`Paste your tracking code into your website source code in `}<inlineCode parentName="p">{`<head>`}</inlineCode>{` section.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!-- LiveSession.io code -->
<script type="text/javascript">
    !function(w, d, t, u) {
        if (w.__ls) return; var f = w.__ls = function() { f.push ? f.push.apply(f, arguments) : f.store.push(arguments)};
        if (!w.__ls) w.__ls = f; f.store = []; f.v = "1.0";

        var ls = d.createElement(t); ls.async = true; ls.src = u;
        var s = d.getElementsByTagName(t)[0]; s.parentNode.insertBefore(ls, s);
    }(window, document, 'script', ('https:' == window.location.protocol ? 'https://' : 'http://') + 'cdn.livesession.io/track.js');

    __ls("init", "YOUR_TRACK_ID" });
    __ls("newPageView"); 
</script>
<!-- END LiveSession.io code -->
`}</code></pre>
    <h3 {...{
      "id": "related-resources"
    }}>{`Related resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://livesession.io/help/how-to-install-livesession-script/"
        }}>{`How to install tracking script?`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://livesession.io/help/how-to-check-if-my-tracking-script-works/"
        }}>{`How to check if my tracking script works?`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      